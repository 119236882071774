import theme from '@shared/assets/styles/theme';

export const StripeOptions = {
  hidePostalCode: true,
  showIcon: true,
  preferredNetwork: ['cartes_bancaires', 'visa', 'mastercard'],
  style: {
    base: {
      color: theme.color.black,
      fontFamily:
        'Inter, BlinkMacSystemFont, -apple-system, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", Helvetica, Arial, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#aaa',
      },
    },
    invalid: {
      color: theme.color.danger,
      iconColor: theme.color.danger,
    },
  },
};

export default {};
