import Stripe from '@shared/services/Stripe';

export default {
  props: {
    stripeAccount: {
      type: String,
      required: false,
    },
  },
  mounted() {
    Stripe.load()
      .then(() => this.init());
  },
};
