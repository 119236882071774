export default {
  stripe: null,
  init(stripeKey, stripeAccount) {
    if (this.stripe) {
      return Promise.resolve();
    }

    return this.load()
      .then(() => (this.stripe = window.Stripe(stripeKey, { stripeAccount })));
  },
  load() {
    if (window.Stripe) {
      return Promise.resolve();
    }

    return new Promise((resolve, reject) => {
      const s = document.createElement('script');
      s.src = 'https://js.stripe.com/v3/';
      s.onload = resolve;
      s.onerror = reject;
      document.body.appendChild(s);
    });
  },
  confirmCardPayment(clientSecret, data = {}) {
    return this.stripe.confirmCardPayment(clientSecret, data).then((res) => {
      if (res.error) {
        return Promise.reject(res.error);
      }
      return res;
    });
  },
  confirmCardSetup(clientSecret, data = {}) {
    return this.stripe.confirmCardSetup(clientSecret, data).then((res) => {
      if (res.error) {
        return Promise.reject(res.error);
      }
      return res;
    });
  },
};
