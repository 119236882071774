<script>
import { required } from 'vuelidate/lib/validators';
import { inArray } from '@shared/config/vuelidate';
import constants from '@shared/config/constants';
import APIUser from '@app/services/API/User';

export default {
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      newUser: {
        email: '',
        role: this.user.role,
      },
    };
  },
  validations: {
    newUser: {
      role: {
        required,
        inArray: inArray(constants.roles),
      },
    },
  },
  methods: {
    handle() {
      this.isLoading = true;
      const newUser = { ...this.newUser };
      APIUser.switchUser(this.user.uuid, newUser)
        .then(({ data }) => {
          this.$showMessage.success();
          this.$emit('done', data);
        })
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>

<template>
  <form class="box" @submit.prevent="dataIsValid(isLoading) && handle()">
    <h2 class="title is-4">
      Remplacer ce membre
    </h2>

    <p>
      Vous pouvez inviter une autre personne pour remplacer
      <strong>{{ user.firstname }} {{ user.lastname }}</strong>
      au sein de votre équipe.
    </p>
    <p class="mt-3">
      Les accès de <strong>{{ user.firstname }} {{ user.lastname }}</strong> seront
      immédiatement suspendus, et le ou la remplaçant(e) recevra un email d'invitation
      pour rejoindre votre équipe.
    </p>

    <hr>

    <b-field label="Adresse email">
      <b-input type="email" v-model="newUser.email" required />
    </b-field>
    <b-field label="Rôle" v-bind="$getErrorProps($v.newUser.role, ['required', 'inArray'])">
      <b-dropdown v-model="newUser.role" expanded append-to-body aria-role="list">
        <template #trigger>
          <b-button class="is-justify-content-space-between" icon-right="chevron-down" expanded>
            <template v-if="newUser.role">
              <span v-t="`roles.${newUser.role}`" />
            </template>
            <template v-else>
              Choisissez le rôle
            </template>
          </b-button>
        </template>
        <b-dropdown-item class="wspace-normal" value="ADMIN" aria-role="listitem">
          <h3 class="has-text-weight-bold">
            Administrateur
          </h3>
          <p>Peut tout faire sauf supprimer l'espace de formation.</p>
        </b-dropdown-item>
        <b-dropdown-item class="wspace-normal" value="TEACHER" aria-role="listitem">
          <h3 class="has-text-weight-bold">
            Formateur
          </h3>
          <p>Peut créer et modifier les formations auxquelles il/elle est assigné(e).</p>
        </b-dropdown-item>
        <b-dropdown-item class="wspace-normal" value="ANALYST" aria-role="listitem">
          <h3 class="has-text-weight-bold">
            Analyste
          </h3>
          <p>Peut tout consulter, mais ne peut rien créer, modifier ou supprimer.</p>
        </b-dropdown-item>
      </b-dropdown>
    </b-field>
    <div class="buttons is-right">
      <b-button type="is-primary" native-type="submit" :loading="isLoading">
        Modifier
      </b-button>
    </div>
  </form>
</template>
